exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-fenix-tsx": () => import("./../../../src/pages/about-fenix.tsx" /* webpackChunkName: "component---src-pages-about-fenix-tsx" */),
  "component---src-pages-bespoke-funeral-packages-tsx": () => import("./../../../src/pages/bespoke-funeral-packages.tsx" /* webpackChunkName: "component---src-pages-bespoke-funeral-packages-tsx" */),
  "component---src-pages-direct-cremation-tsx": () => import("./../../../src/pages/direct-cremation.tsx" /* webpackChunkName: "component---src-pages-direct-cremation-tsx" */),
  "component---src-pages-funeral-directors-tsx": () => import("./../../../src/pages/funeral-directors.tsx" /* webpackChunkName: "component---src-pages-funeral-directors-tsx" */),
  "component---src-pages-graveside-burial-tsx": () => import("./../../../src/pages/graveside-burial.tsx" /* webpackChunkName: "component---src-pages-graveside-burial-tsx" */),
  "component---src-pages-guidance-tsx": () => import("./../../../src/pages/guidance.tsx" /* webpackChunkName: "component---src-pages-guidance-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lasting-power-attorney-tsx": () => import("./../../../src/pages/lasting-power-attorney.tsx" /* webpackChunkName: "component---src-pages-lasting-power-attorney-tsx" */),
  "component---src-pages-legal-services-tsx": () => import("./../../../src/pages/legal-services.tsx" /* webpackChunkName: "component---src-pages-legal-services-tsx" */),
  "component---src-pages-locations-strapi-location-type-slug-strapi-location-hub-url-part-strapi-location-city-city-url-strapi-location-slug-tsx": () => import("./../../../src/pages/locations/{StrapiLocation.type__slug}/{StrapiLocation.hub__urlPart}/{StrapiLocation.city__cityUrl}/{StrapiLocation.slug}.tsx" /* webpackChunkName: "component---src-pages-locations-strapi-location-type-slug-strapi-location-hub-url-part-strapi-location-city-city-url-strapi-location-slug-tsx" */),
  "component---src-pages-locations-strapi-location-type-slug-strapi-location-hub-url-part-strapi-location-city-city-url-tsx": () => import("./../../../src/pages/locations/{StrapiLocation.type__slug}/{StrapiLocation.hub__urlPart}/{StrapiLocation.city__cityUrl}.tsx" /* webpackChunkName: "component---src-pages-locations-strapi-location-type-slug-strapi-location-hub-url-part-strapi-location-city-city-url-tsx" */),
  "component---src-pages-locations-strapi-location-type-slug-strapi-location-hub-url-part-tsx": () => import("./../../../src/pages/locations/{StrapiLocation.type__slug}/{StrapiLocation.hub__urlPart}.tsx" /* webpackChunkName: "component---src-pages-locations-strapi-location-type-slug-strapi-location-hub-url-part-tsx" */),
  "component---src-pages-locations-strapi-location-type-slug-tsx": () => import("./../../../src/pages/locations/{StrapiLocation.type__slug}.tsx" /* webpackChunkName: "component---src-pages-locations-strapi-location-type-slug-tsx" */),
  "component---src-pages-locations-tsx": () => import("./../../../src/pages/locations.tsx" /* webpackChunkName: "component---src-pages-locations-tsx" */),
  "component---src-pages-memorial-index-tsx": () => import("./../../../src/pages/memorial/index.tsx" /* webpackChunkName: "component---src-pages-memorial-index-tsx" */),
  "component---src-pages-our-funerals-tsx": () => import("./../../../src/pages/our-funerals.tsx" /* webpackChunkName: "component---src-pages-our-funerals-tsx" */),
  "component---src-pages-product-category-tsx": () => import("./../../../src/pages/product-category.tsx" /* webpackChunkName: "component---src-pages-product-category-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-service-at-crematorium-tsx": () => import("./../../../src/pages/service-at-crematorium.tsx" /* webpackChunkName: "component---src-pages-service-at-crematorium-tsx" */),
  "component---src-pages-standardised-price-list-tsx": () => import("./../../../src/pages/standardised-price-list.tsx" /* webpackChunkName: "component---src-pages-standardised-price-list-tsx" */),
  "component---src-pages-thanks-for-your-order-tsx": () => import("./../../../src/pages/thanks-for-your-order.tsx" /* webpackChunkName: "component---src-pages-thanks-for-your-order-tsx" */),
  "component---src-pages-wills-tsx": () => import("./../../../src/pages/wills.tsx" /* webpackChunkName: "component---src-pages-wills-tsx" */),
  "component---src-templates-article-post-tsx": () => import("./../../../src/templates/article-post.tsx" /* webpackChunkName: "component---src-templates-article-post-tsx" */),
  "component---src-templates-city-direct-cremation-tsx": () => import("./../../../src/templates/city-direct-cremation.tsx" /* webpackChunkName: "component---src-templates-city-direct-cremation-tsx" */),
  "component---src-templates-city-hub-post-tsx": () => import("./../../../src/templates/city-hub-post.tsx" /* webpackChunkName: "component---src-templates-city-hub-post-tsx" */),
  "component---src-templates-city-post-seo-tsx": () => import("./../../../src/templates/city-post-seo.tsx" /* webpackChunkName: "component---src-templates-city-post-seo-tsx" */),
  "component---src-templates-city-post-tsx": () => import("./../../../src/templates/city-post.tsx" /* webpackChunkName: "component---src-templates-city-post-tsx" */),
  "component---src-templates-product-post-tsx": () => import("./../../../src/templates/product-post.tsx" /* webpackChunkName: "component---src-templates-product-post-tsx" */)
}

