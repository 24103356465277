/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

exports.onRouteUpdate = ({location, prevLocation}) => {

  const trustbox = document.getElementById('trustbox');
  window?.Trustpilot?.loadFromElement(trustbox);
  var element = document.getElementsByClassName("trustpilot-microtrustscore");
  for (var i = 0; i < element.length; i++) {
    window?.Trustpilot?.loadFromElement(element[i]);
  }

  if (location && location.state)
    location.state.referrer = prevLocation ? prevLocation.pathname : null
}